import React, { useState, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { UserProvider, useUser } from './context/UserContext';
import { routeRestrictions } from './utils/routeRestrictions';
import FadeLoader from "react-spinners/FadeLoader";
import ErrorFallback from './components/errorFallback';
import "./assets/css/Account.css";
import "./assets/css/Activities.css";
import "./assets/css/Calculator.css";
import "./assets/css/CampaignDetail.css";
import "./assets/css/Campaigns.css";
import "./assets/css/Dashboard.css";
import "./assets/css/DistributionReport.css";
import "./assets/css/header.css";
import "./assets/css/Phone-section.css";
import "./assets/css/Realtime.css";
import "./assets/css/Report.css";
import "./assets/css/ScriptDetail.css";
import "./assets/css/Setting.css";
import "./assets/css/Sidebar.css";
// import "./assets/css/SignOut.css";
import "./assets/css/Stat.css";
import "./assets/css/style.css";
import "./assets/css/Ticket.css";
import "./assets/css/bootstrap.css";
import "./assets/css/Allcontact.css";
import "./assets/css/Vox.css";
import Sidebar from "./components/sidebar";
import EventModal from './components/eventModal';
import OutboundCallModal from './components/outboundCallModal';
const Login = React.lazy(() => import('./pages/login'));
const Home = React.lazy(() => import('./pages/home'));
const Contact = React.lazy(() => import('./pages/contact'));
const Contactgroup = React.lazy(() => import('./pages/contactGroups'));
const Knowledgebase = React.lazy(() => import('./pages/Knowledgebase'));
const Setting = React.lazy(() => import('./pages/settings'));
const Myaccount = React.lazy(() => import('./pages/Myaccount'));
const ContactGroupDetails = React.lazy(() => import('./pages/contactGroupDetails'));
const CallReport = React.lazy(() => import('./pages/callReport'));
const Campaigns = React.lazy(() => import('./pages/Campaigns'));
const CallReportQueueDetail = React.lazy(() => import('./pages/callReportQueueDetail'));
const CallReportInboundOutboundDetail = React.lazy(() => import('./pages/callReportInboundOutboundDetail'));
const CallAgentReport = React.lazy(() => import('./pages/callAgentReport'));
const CallTrunkReport = React.lazy(() => import('./pages/callTrunkReport'));
const Scripts = React.lazy(() => import('./pages/Scripts'));
const Activities = React.lazy(() => import('./pages/Activities'));
const Ticket = React.lazy(() => import("./pages/Ticket"));
const CampaignDetail = React.lazy(() => import("./pages/CampaignDetail"));
const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const CampaignCall = React.lazy(() => import('./pages/CampaignCall'));
const AgentReport = React.lazy(() => import('./pages/agentReport'));
const CallTeamSpecificReport = React.lazy(() => import('./pages/callTeamReport'));
const DistributionReport = React.lazy(() => import('./pages/DistributionReport/index'));
const AgentDistributionReport = React.lazy(() => import('./pages/agentDistributionReport'));
const TeamReport = React.lazy(() => import('./pages/teamReport'));
const GlobalContact = React.lazy(() => import('./pages/GlobalContact'));
const PageNotFound = React.lazy(() => import('./pages/pageNotFound'));
const Realtime = React.lazy(() => import('./pages/Realtime'));
const WallBoard = React.lazy(() => import('./pages/wallBoard'));
const AdditionalData = React.lazy(() => import('./pages/AdditionalData'));
const GetCallStatus = React.lazy(() => import('./pages/GetCallStatus'));
const Stat = React.lazy(() => import('./pages/Stat'));
const AgentLiveCount = React.lazy(() => import('./pages/AgentLiveCount'));
const Scheduler = React.lazy(() => import('./pages/Scheduler'));
const KnowledgeBaseDetail = React.lazy(() => import('./pages/KnowledgeBaseDetail'));
const ContactCallModal = React.lazy(() => import('./components/contactCallModal'));
const ContactGroupBulkUpdate = React.lazy(() => import('./pages/contactGroupBulkUpdate'));
const CallDetailRecord = React.lazy(() => import('./pages/CallDetailRecord'));
const AgentBreakDetail = React.lazy(() => import('./pages/AgentBreakDetail'));
const AgentLogoutDetail = React.lazy(() => import('./pages/AgentLogoutDetail'));


const ProtectedRoute = ({ children }) => {
  const { user } = useUser();
  const token = localStorage.getItem('token');
  const currentPath = window.location.pathname;
  
  if (!user && !token) {
    return <Navigate to="/login" />;
  }

  const restrictedRoutes = routeRestrictions[user?.role] || [];
  if (restrictedRoutes.some(route => currentPath.startsWith(route))) {
    return <Navigate to="/dashboard" />;
  }
  
  return children;
};

const App = () => {

  const [toggleExpand, setToggleExpand] = useState(false);
  const [contentMinimized, setContentMinimized] = useState(false);
  const [showContactCallModal, setShowContactCallModal] = useState(false);
  const [contactCallModalData, setContactCallModalData] = useState({});
  const location = window.location.pathname; 

  const handleCloseContactCallModal = () => setShowContactCallModal(false);

  const toggleclass = () => {
    setToggleExpand(!toggleExpand);
  };

  return (
    <UserProvider>
      <Router>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            window.location.reload();
          }}
        >
        <Suspense fallback={
          <div>
            <Sidebar />
            <div className="loader-overlay">
              <FadeLoader
                color="#808080"
                height={10}
                width={3}
                radius={0}
                margin={-5}
              />
            </div>
          </div>
        }>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
          <Route path="/contact" element={<ProtectedRoute><Contact setShowContactCallModal={setShowContactCallModal} setContactCallModalData={setContactCallModalData} /></ProtectedRoute>} />
          <Route path="/contactgroup" element={<ProtectedRoute><Contactgroup /></ProtectedRoute>} />
          <Route path="/contactgroup/:contactGroupId" element={<ProtectedRoute><ContactGroupBulkUpdate/></ProtectedRoute>} />
          <Route path="/knowledgebase" element={<ProtectedRoute><Knowledgebase /></ProtectedRoute>} />
          <Route path="/settings" element={<ProtectedRoute><Setting/></ProtectedRoute>} />
          <Route path="/myaccount" element={<ProtectedRoute><Myaccount/></ProtectedRoute>} />
          <Route path="/campaigns" element={<ProtectedRoute><Campaigns/></ProtectedRoute>} />
          <Route path="/campaign-detail/:campaignId" element={<ProtectedRoute><CampaignDetail setShowContactCallModal={setShowContactCallModal} setContactCallModalData={setContactCallModalData} /></ProtectedRoute>} />
          <Route path="/campaign-call/:campaignId" element={<ProtectedRoute><CampaignCall/></ProtectedRoute>} />
          <Route path="/scripts" element={<ProtectedRoute><Scripts/></ProtectedRoute>} />
          <Route path="/activities" element={<ProtectedRoute><Activities contentMinimized={contentMinimized} toggleExpand={toggleExpand} toggleclass={toggleclass} setShowContactCallModal={setShowContactCallModal} setContactCallModalData={setContactCallModalData}/></ProtectedRoute>} />
          <Route path="/ticket" element={<ProtectedRoute><Ticket /></ProtectedRoute>} />
          <Route path="/ticket" element={<Ticket />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/Realtime" element={<Realtime />} />
          <Route path="/group/:id" element={<ProtectedRoute><ContactGroupDetails setShowContactCallModal={setShowContactCallModal} setContactCallModalData={setContactCallModalData} /></ProtectedRoute>} />
          <Route path="/callreport/:reportType" element={<ProtectedRoute><CallReport /></ProtectedRoute>} />
          <Route path="callDetailRecord" element={<ProtectedRoute><CallDetailRecord /></ProtectedRoute>} />
          <Route path="/queueDetail/:type/:startDate/:endDate" element={<ProtectedRoute><CallReportQueueDetail /></ProtectedRoute>} />
          <Route path="/agentReport/:type/:startDate/:endDate" element={<ProtectedRoute><CallAgentReport /></ProtectedRoute>} />
          <Route path="/trunkReport/:type/:startDate/:endDate" element={<ProtectedRoute><CallTrunkReport /></ProtectedRoute>} />
          <Route path="/agentReport" element={<ProtectedRoute><AgentReport /></ProtectedRoute>} />
          <Route path="/incomingDetail/:type/:startDate/:endDate" element={<ProtectedRoute><CallReportInboundOutboundDetail /></ProtectedRoute>} />
          <Route path="/outboundDetail/:type/:startDate/:endDate" element={<ProtectedRoute><CallReportInboundOutboundDetail /></ProtectedRoute>} />
          <Route path="/teamReport/:type/:startDate/:endDate" element={<ProtectedRoute><CallTeamSpecificReport /></ProtectedRoute>} />
          <Route path="/queueDetail/:date" element={<ProtectedRoute><CallReportQueueDetail /></ProtectedRoute>} />
          <Route path="/agentReport/:type/:startDate/:endDate" element={<ProtectedRoute><AgentReport /></ProtectedRoute>} />
          <Route path="/distributionReport" element={<ProtectedRoute><DistributionReport /></ProtectedRoute>} />
          <Route path="/distributionReport/:agentId/:startDate/:endDate" element={<ProtectedRoute><AgentDistributionReport /></ProtectedRoute>} />
          <Route path="/teamReport" element={<ProtectedRoute><TeamReport /></ProtectedRoute>} />
          <Route path="/wallBoard" element={<ProtectedRoute><WallBoard/></ProtectedRoute>} />
          <Route path="/additionalData" element={<ProtectedRoute><AdditionalData/></ProtectedRoute>} />
          <Route path="/getCallStatus" element={<ProtectedRoute><GetCallStatus/></ProtectedRoute>} />
          <Route path="/globalContact" element={<ProtectedRoute><GlobalContact setShowContactCallModal={setShowContactCallModal} setContactCallModalData={setContactCallModalData} /></ProtectedRoute>} />
          <Route path="/stats" element={<ProtectedRoute><Stat /></ProtectedRoute>} />
          <Route path="/knowledgebase/:id" element={<KnowledgeBaseDetail />} />
          <Route path="/agentLiveCount" element={<ProtectedRoute><AgentLiveCount /></ProtectedRoute>} />
          <Route path="/breakDetail/:date/:agentId" element={<ProtectedRoute><AgentBreakDetail /></ProtectedRoute>} />
          <Route path="/logoutDetail/:date/:agentId" element={<ProtectedRoute><AgentLogoutDetail /></ProtectedRoute>} />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
        {location !== "/login" && <EventModal /> }
        </Suspense>
        {showContactCallModal && 
          <ContactCallModal onClose={handleCloseContactCallModal} data={contactCallModalData} />
        }
        <OutboundCallModal />
      </ErrorBoundary>
      </Router>
    </UserProvider>
  );
};

export default App;
